.Box {
  padding: betterClamp(50px, 80px) betterClamp($page-margin-small, 90px);
  
  @include media-breakpoint-down(large) {
    background: $color-limestone-20;
  }

  @include media-breakpoint-up(large) {
    background: #fff;
    border-bottom: 7px solid $color-brand;
  }
}