.Link {
  font-size: $font-size-large;
  border-bottom: 2px solid  var(--link-underline-color, $color-brand);
  font-weight: bold;
  display: inline-block;
  color: $color-brand;

  svg {
    vertical-align: text-top;
  }

  &:not(:first-child) {
    margin-top: 0.5em;
  }

  // Modifiers

  &--sm {
    font-size: $font-size-small;
  }
}