%list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 0;
  }
}

%link-inverted {
  text-decoration-color: transparent;

  &:hover,
  &:active {
    text-decoration-color: #fff;
  }
}