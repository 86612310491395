.WorkbenchToolboxCategory {
  @include media-breakpoint-up(large) {
    display: grid;
    grid-template-columns: 9fr 3fr;
    grid-template-rows: min-content auto;
    column-gap: 75px;
  }
  
  & + & {
    padding-top: 20px;
    border-top: 1px solid $border-color;
    margin-top: 40px;
  }

  &-heading {
    grid-column-start: span 2;
  }

  &-main {
    min-width: 0;
    
    @include media-breakpoint-down(large) {
      margin-bottom: 30px;
    }
  }

  &-aside {
    min-width: 0;

    @include media-breakpoint-down(large) {
      padding: 0 20px;
    }
  }

  &-navListTitle {
    @include h5;
  }
}


.WorkbenchToolboxTool {
  $comp: &;
  
  background: $color-limestone-20;
  border-left: 3px solid $color-vivid-jade;

  & + & {
    margin-top: 20px;
  }

  &-title {
    border-bottom: 1px solid $border-color;
    padding: 15px 0;
    margin: 0 20px;

    @include media-breakpoint-up(medium) {
      margin-left: 30px;
    }
  }

  &-body {
    padding: 15px 20px 30px;
    font-size: $font-size-small;

    @include media-breakpoint-up(medium) {
      display: grid;
      grid-template-columns: 4fr 220px;
      padding-bottom: 40px;
      padding-left: 30px;
    }
  }

  &-desc {
    *:last-child {
      margin-bottom: 0;
    }
  }

  &-apps {
    @extend %list-unstyled;

    margin-top: 20px;
    padding-top: 12px;
    border-top: 1px solid $border-color;
    
    @include media-breakpoint-up(medium) {
      padding-left: 20px;
      margin-left: 20px;
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
      border-left: 1px solid $border-color;
    }
  }

  &-app {
    & + & {
      border-top: 1px solid $border-color;
      margin-top: 12px;
      padding-top: 12px;
    }
  }
  
  &-appIcon {
    display: block;
    font-size: 24px;
    margin-right: 8px;
    color: #fff;
  }

  &-appLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-appLabel {
    display: flex;
    align-items: center;
    font-weight: bold;
    line-height: 1.3;
  }

  &-appExtIcon {
    margin-left: auto;
    flex: none;
    font-size: $font-size-base;
    margin-left: 10px;
    color: $color-limestone-80;
  }

  // Modifiers

  &--delivery {
    border-left: 0;

    #{$comp}-title {
      font-size: $font-size-small;
      margin: 0 0 0.15em;
      padding: 0;
      border: 0;
    }

    #{$comp}-img {
      padding-right: 20px;
    }

    #{$comp}-body {
      @include media-breakpoint-up(medium) {
        grid-template-columns: 100px auto 220px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
      }
    }
  }
}