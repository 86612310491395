.VideoPlayer {
  position: relative;
  height: 0;
  padding-bottom: percentage(9/16);
  width: 100%;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
    background:
      linear-gradient(180deg, rgba(0, 0, 0, 0) 65.53%, rgba(0, 0, 0, 0.3) 85.94%),
      rgba(0, 0, 0, 0.2);
  }

  &-title {
    font-size: $font-size-large;
    font-weight: bold;
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 30px;
    margin: 0;
    text-align: center;
    color: #fff;
  }

  &-thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 74px;
    height: 74px;
    transform: translate(-50%, -50%);
    z-index: 2;
    background: #fff svg-load('../assets/icons/play.svg', fill=$color-base) 50% 50% / 33% auto no-repeat;
    border-radius: 50%;
  }

  &--card {
    min-height: 330px;
    
    @include media-breakpoint-up(large) {
      min-height: 180px;
      height: 100%;
    }
  }
}