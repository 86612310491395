.WorkbenchHeader {
  &-inner {
    @include container;
    padding-top: 40px;
    padding-bottom: betterClamp(30px, 40px);

    @include media-breakpoint-up(medium) {
      display: grid;
      grid-template-columns: 6fr 3fr 3fr;
    }
  }

  &-hgroup {
    margin-bottom: 30px;

    @include media-breakpoint-up(medium) {
      margin-bottom: 0;
    }
  }

  &-heading {
    font-size: betterClamp(26px, 32px);
    font-weight: normal;
    margin: 0 0 0.25em;

    @include media-breakpoint-down(medium) {
      strong {
        display: block;
      }
    }
  }

  &-desc {
    color: $color-slate-80;
    font-size: $font-size-small;
    margin: 0;
  }

  &-meta {
    position: relative;
    border-left: 1px solid $border-color;
    padding-left: 46px;
    font-weight: bold;
    margin: 0;
    
    &::before {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      display: block;
      width: 28px;
      height: 28px;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
    }

    dt {
      font-size: betterClamp(12px, 13px);
      color: $color-slate-60;
      font-weight: normal;
    }

    dd {
      margin: 0;
      color: $color-base;
      font-size: betterClamp(12px, 13px);
    }

    @include media-breakpoint-down(medium) {
      & + & {
        margin-top: 20px;
      }
    }

    @include media-breakpoint-up(medium) {
      padding-left: 55px;
      margin: 0 0 0 $grid-gap;

      &::before {
        top: 0;
        left: 20px;
        transform: none;
      }
    }

    // Modifiers

    &--location::before {
      background-image: svg-load('../assets/icons/pin.svg', fill=$color-slate-40);
    }

    &--organisation::before {
      background-image: svg-load('../assets/icons/user.svg', fill=$color-slate-40);
      background-size: 90%;
    }
  }
}