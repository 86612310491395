.Pagination {
  @extend %list-unstyled;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  &-item {
    margin: 0 10px;
  }

  &-link {
    font-size: 12px;
    padding: 5px;
    line-height: 1;
    display: block;
    border: 1px solid transparent;

    &--prevnext {
      border-color: $border-color;
    }

    &.is-disabled {
      opacity: 0.3;
    }
  }
}