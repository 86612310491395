@mixin heading {
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 0.75em;
  margin-top: 0;
  color: $color-base;
}

%heading {
  @include heading();
}

@mixin h1() {
  @extend %heading;
  font-size: betterClamp(26px, 32px);
}

@mixin h2() {
  @extend %heading;
  font-size: betterClamp(22px, 24px);
}

@mixin h3() {
  @extend %heading;
  font-size: 18px;
}

@mixin h4() {
  @extend %heading;
  font-size: 16px;
  margin-bottom: 1em;
}

@mixin h5() {
  @extend %heading;
  font-weight: normal;
  color: $color-slate-40;
  font-size: $font-size-small;
}

@mixin h3-to-h1() {
  @include h3;
  
  @include media-breakpoint-up(large) {
    @include h1();
  }
}

@mixin h2-to-h3() {
  @include h2;
  
  @include media-breakpoint-up(large) {
    @include h3();
  }
}

h1,
.H1 {
  @include h1;
}

h2,
.H2 {
  @include h2;
}

h3,
.H3 {
  @include h3;
}

h4,
.H4 {
  @include h4;
}

h5,
.H5 {
  @include h5;
}

.H3toH1 {
  @include h3-to-h1();
}

.H2toH3 {
  @include h2-to-h3();
}

%Lead,
.Lead {
  font-size: $font-size-base;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: $color-base;
}

.Rule {
  border-top: 1px solid $border-color;
  margin: $grid-gap * 1.5 auto;
  color: transparent;
}

