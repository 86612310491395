.Icon {
  display: inline-block;
  stroke: none;
  fill: currentColor;
  height: 1em;
  vertical-align: -0.2em;
  width: 1em;
  &.success {
    color: $color-vivid-jade;
  }
  &.warning {
    color: $color-deep-canyon;
  }
  &.default {
    color: $color-slate-40;
  }
}
