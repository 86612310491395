@use 'sass:color';

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  color: $color-slate-80;
  font-family: $font-family;
  font-size: $font-size-base;
  line-height: 1.54;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background: $color-slate;
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s ease,
      visibility 0.3s ease;
    will-change: opacity;
  }

  &.has-overlay {
    @include scroll-lock;
    
    &::before {
      opacity: 0.5;
      visibility: visible;
    }
  }
}

img {
  max-width: 100%;
  display: block;
  height: auto;
}

ul,
ol,
p,
address {
  margin: 0 0 1em;
}

ul {
  padding-left: 1em;
}

li {
  margin-bottom: 0.5em;
}

a {
  color: var(--link-color, $color-link);
  text-decoration: none;
  transition:
    color 0.15s ease,
    text-decoration 0.15s ease,
    text-underline-offset 0.15s ease;

  &:hover,
  &:active {
    color: var(--link-hover-color, $color-link);
  }
}

a,
input,
button,
select {
  &:focus-visible {
    outline: 2px dashed $color-brand;
    outline-offset: 0.2em;
    mask-image: none;
  }
}

address {
  font-style: normal;
}

input {
  font-family: inherit;
}

input[type='search'] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
}

::selection {
  background: $color-highlight;
}

::marker {
  font-size: 1em;
}

strong {
  font-weight: bold;
}