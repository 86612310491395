.SearchResultsHeading {
  @include h3-to-h1;

  small {
    font-size: 0.45em;
    font-weight: normal;
    color: $color-slate-40;
    vertical-align: 1em;
  }

  @include media-breakpoint-up(large) {
    border-bottom: 1px solid $border-color !important;
    padding-bottom: betterClamp(20px, 30px) !important;
    margin-bottom: betterClamp(20px, 30px) !important;
  }
}
