.Hamburger {
  display: block;
  width: 24px;

  @include media-breakpoint-up(large) {
    display: none;
  }

  &::after,
  &::before,
  &-icon {
    background-color: $color-base;
    content: '';
    display: block;
    height: 3px;
    transition: all 0.2s ease-in-out;
  }

  &-icon {
    margin: 4px 0;
  }

  &[aria-expanded='true']::before {
    transform: translateY(7px) rotate(135deg);
  }

  &[aria-expanded='true']::after {
    transform: translateY(-7px) rotate(-135deg);
  }

  &[aria-expanded='true'] &-icon {
    transform: scale(0);
  }
}
