.WorkbenchTabs {
  @include tab-container();

  &-tab {
    @include tab();
  }
}

.WorkbenchTabPanel {
  background: #fff;
  padding: 50px betterClamp($page-margin-small, 50px);

  @include media-breakpoint-down(large) {
    margin: 0 calc(-1 * #{betterClamp($page-margin-small, $page-margin-large)});
    padding-left: betterClamp($page-margin-small, $page-margin-large);
    padding-right: betterClamp($page-margin-small, $page-margin-large);
  }
}