@use 'sass:color';
@use 'sass:math';

.Button {
  $comp: &;

  position: relative;
  align-items: stretch;
  color: $color-slate;
  display: inline-flex;
  align-items: center;
  text-align: center;
  font-size: $font-size-small;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-decoration: none;
  transition: background 0.15s ease, color 0.15s ease;
  height: $btn-height;
  padding: 0 15px;
  height: 34px;
  border-radius: 20px;
  white-space: nowrap;

  &:hover,
  &:active {
    cursor: pointer;
  }

  &[hidden] {
    display: none;
  }

  @media print {
    display: none !important;
  }

  &-icon {
    position: relative;
    z-index: 1;
    font-size: 1em;
    color: inherit;

    &--first {
      margin-right: 6px;
    }

    &--last {
      margin-left: 6px;
    }
  }

  // Modifiers

  &--block {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;

    & + & {
      margin-top: 15px;
    }
  }

  &--default {
    background: $color-link;
    color: #fff;

    &:hover,
    &:active {
      color: #fff;
    }
  }
  &--link {
    background: none;
    color: $color-link;
    height: auto;
  }

  &--primary {
    background: $color-morning-grass;
    color: $color-base;

    &:hover,
    &:active {
      color: $color-base;
    }
  }
  &--exclaim {
    background: $color-morning-grass;
    color: $color-base;
    font-size: 18px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 12px;
    padding-left: 12px;
    width: 34px;
    height: 34px;
    &:hover,
    &:active {
      color: $color-base;
    }
  }

  &--secondary {
    border: 1px solid $color-vivid-jade-60;
    color: $color-vivid-jade;
    background: none;
    &:hover,
    &:active {
      color: $color-vivid-jade;
      border: 1px solid $color-vivid-jade;
    }
  }

  &--active {
    background: $color-slate;
    color: white;
    &:hover,
    &:active {
      color: white;
      opacity: 0.8;
    }
  }
  &--grey {
    background: $color-slate-10;
    color: $color-slate;
    &:hover,
    &:active {
      color: $color-slate;
      opacity: 0.8;
    }
  }

  &:disabled {
    background: $color-slate-10;
    color: $color-slate-40;
    cursor: not-allowed;
  }

  //Sizing

  &--sm {
    height: 32px;
    padding: 0 12px;
    font-size: 12px;
  }

  &--xs {
    height: 24px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 500;
  }
}

.ButtonGroup {
  display: flex;
  flex-wrap: wrap;

  .Button {
    margin: 0 $grid-gap / 2 $grid-gap / 2 0;

    &:last-child {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(medium) {
    flex-direction: column;

    .Button {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.Delete {
  color: $color-error;
  font-size: $font-size-small;
  letter-spacing: 0.01em;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  svg {
    font-size: 22px;
    margin-right: 8px;
    margin-top: -3px;
  }
}
