.Rating {
  display: flex;

  &-star {
    font-size: 20px;
    color: var(--star-color, $color-slate-20);

    & + & {
      margin-left: 4px;
    }

    svg {
      display: block;
    }

    &.is-active {
      color: $color-morning-grass;
    }
  }

  &-select {
    @include sr-only;
  }
}