.ContentBlock {
  margin-bottom: betterClamp(60px, 100px);

  @include media-breakpoint-up(large) {
    display: grid;
    grid-template-columns: 8.5fr 3.5fr;
  }

  &-main {
    margin-bottom: 60px;

    @include media-breakpoint-up(large) {
      padding-right: 50px;
      margin-bottom: 0;
    }
  }

  &-aside {
    @include media-breakpoint-down(large) {
      background: $color-limestone-20;
      padding: 40px betterClamp($page-margin-small, $page-margin-large);
      margin: 0 calc(-1 * #{betterClamp($page-margin-small, $page-margin-large)});
    }
    
    @include media-breakpoint-up(large) {
      padding-left: 50px;
      border-left: 1px solid $border-color;
    }
  }
}