.SearchResults {
  margin-bottom: betterClamp(40px, 120px);

  @include media-breakpoint-up(large) {
    display: grid;
    grid-template-columns: 260px auto;
  }

  &-filters {
    @include media-breakpoint-down(large) {
      margin-bottom: 30px;
    }

    @include media-breakpoint-up(large) {
      padding-right: 50px;
      border-right: 1px solid $border-color;
      min-width: 0;
    }
  }

  &-filtersHeading {
    @include h4;

    @include media-breakpoint-down(large) {
      margin: 0;
    }
  }

  &-filtersToggle {
    font-weight: bold;
    
    @include media-breakpoint-down(large) {
      background: $color-limestone-20;
      padding: 10px 14px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;

      &::after {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        flex: none;
        margin-left: 10px;
        background: svg-load('../assets/icons/arrow-down.svg', fill=$color-link) 50% 50% / 100% auto no-repeat;
        transition: transform 0.15s ease;
      }

      &[aria-expanded='true']::after {
        transform: rotate(180deg);
      }
    }
  }

  &-filtersList {
    @include media-breakpoint-down(large) {
      padding: 0 14px;
      @include accordion-panel;
    }
  }

  &-body {
    @include media-breakpoint-up(large) {
      padding-left: 40px;
      min-width: 0;
    }
  }

  &-toolboxCategory {
    & + & {
      padding-top: 20px;
      border-top: 1px solid $border-color;
      margin-top: 40px;
    }
  }
}

