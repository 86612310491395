.WorkbenchContacts {
  @include media-breakpoint-up(large) {
    display: flex;
  }

  &-navlist {
    @extend %list-unstyled;

    @include media-breakpoint-down(large) {
      margin-top: -20px;
    }
  
    @include media-breakpoint-up(large) {
      padding-right: 60px;
      flex: 0 0 260px;
    }
  }

  &-navlistItem {
    & + & {
      border-top: 1px solid $border-color;
    }

    @include media-breakpoint-up(large) {
      & + & {
        margin-top: 10px;
        padding-top: 10px;
      }
    }
  }

  &-navlistLink {
    line-height: 1.3;
    display: flex;
    align-items: center;
    font-size: $font-size-base;

    @include media-breakpoint-down(large) {
      justify-content: space-between;
      font-weight: bold;
      color: $color-slate;
      padding: 20px 0;

      &::after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background: svg-load('../assets/icons/arrow-right.svg', fill=$color-brand) no-repeat;
        background-size: 100% auto;
        left: 10px;
      }
    }

    @include media-breakpoint-up(large) {
      color: $color-slate-60;

      &.is-active {
        font-weight: bold;
        color: $color-slate;

        &::before {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: $color-brand;
          margin-right: 10px;
        }
      }
    }
  }

  &-main {
    @include media-breakpoint-down(large) {
      &:not(:first-child) {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
        transform: translateX(100%);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 200;
        display: flex;
        flex-direction: column;
        background: #fff;

        &.is-active {
          visibility: visible;
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    @include media-breakpoint-up(large) {
      padding-left: 50px;
      flex: 0 0 calc(100% - 260px);
      border-left: 1px solid $border-color;
    }
  }

  &-mainBody {
    @include media-breakpoint-down(large) {
      padding: 20px betterClamp($page-margin-small, $page-margin-large) 40px;
      overflow-y: auto;
      flex: 1;
    }
  }

  &-back {
    display: flex;
    align-items: center;
    font-size: $font-size-base;
    font-weight: bold;
    color: $color-base;
    line-height: 1.3;

    svg {
      color: $color-brand;
      margin-right: 8px;
    }
  }

  &-header {
    background: $color-limestone-20;
    padding: 20px betterClamp($page-margin-small, $page-margin-large);

    @include media-breakpoint-up(large) {
      display: none;
    }
  }
}

.WorkbenchKeyContacts {
  @extend %list-unstyled;
  display: grid;
  grid-template-rows: auto;
  gap: $grid-gap;

  &:not(:last-child) {
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(large) {
    grid-template-columns: 1fr 1fr;
  }

  &-item {
    padding: 20px betterClamp(20px, 35px);
    background: $color-limestone-20;
  }

  &-name {
    margin-bottom: 0.5em;
  }

  &-role {
    margin-bottom: 0.25em;
    font-size: $font-size-small;
    color: $color-slate-60;
  }
}

.WorkbenchContactsCategory {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}