.Gallery {
  &:not(:last-child) {
    margin: 40px auto;
  }

  &-imageOverlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    background: rgb($color-slate, 0.5);
    transition: visibility 0.3s ease, opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 30px;
  }

  &-image {
    position: relative;

    &:hover .Gallery-imageOverlay {
      visibility: visible;
      opacity: 1;
    }
  }
}

.GalleryImage {
  position: relative;
  width: 100%;
  height: 80vh;
  margin: 8vh 0 12vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-img {
    object-fit: contain;
    object-position: 50% 50%;
    display: block;
    width: 100%;
    max-height: 100%;
    min-height: 0;
  }

  &-caption {
    padding-top: 20px;
    color: #fff;
    text-align: center;
  }
}