.FormRow {
  margin: 0 0 15px;
  padding: 0;
  border: 0;

  &:last-child {
    margin-bottom: 0;
  }

  &--inline {
    display: grid;
    grid-template-columns: 105px auto;
    gap: $grid-gap;
    align-items: baseline;
  }
}

fieldset.FormRow {
  border-top: 1px solid $border-color;
  padding-top: 30px;

  legend {
    display: contents;
  }

  & {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $border-color;
  }

  &.FormRow--short {
    margin-top: 15px;
    padding-top: 15px;
  }

  .disabled {
    position: relative;
    svg.Icon {
      right: 12px;
      top: 12px;
      position: absolute;
      z-index: 10;
      opacity: 0.6;
    }
  }
}

.FormLabel {
  color: $color-base;
  margin-bottom: 0.5em;
  line-height: 1.3;
  display: block;
  font-size: $font-size-small;

  .FormRow--inline & {
    margin-bottom: 0;
  }
}

.FormActions {
  border-top: 1px solid $border-color;
  padding: 30px 0 0;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-action {
    &:last-child {
      margin-left: auto;
    }
  }
}

%FormControl,
.FormControl {
  display: block;
  width: 100%;
  border: 1px solid $form-control-border-color;
  height: $btn-height;
  font-size: 16px;
  color: $color-base;
  padding: 10px;
  font-family: inherit;
  transition: border-color 0.3s ease;
  background: #fff;
  appearance: none;
  border-radius: 10px;
  font-size: $font-size-small;

  &:focus-visible {
    border-color: $color-highlight;
    outline: 0;
  }

  &::placeholder {
    color: $color-base;
    opacity: 0.6;
  }

  &[aria-invalid='true'] {
    border-color: $color-error;
  }

  // Modifiers

  &--sm {
    height: 32px;
  }

  &--lg {
    height: 50px;
  }

  &--inverted {
    background: rgba(#fff, 0.15);
    border-color: $border-color-inverse;
    color: #fff;

    &::placeholder {
      color: #fff;
      opacity: 0.7;
    }
  }
}

textarea.FormControl {
  height: auto;
  resize: vertical;
}

.FormMessage,
.FieldsetMessage {
  color: $color-error;
  font-size: $font-size-small;
  margin-top: 0.5em;

  &:empty {
    display: none;
  }
}

.FieldsetMessage {
  display: none;

  [aria-invalid='true'] & {
    display: block;
  }
}

.FormSubmit {
  display: flex;
  justify-content: flex-end;
}

input:disabled {
  background-color: #f0f3f5;
  border: none;
  position: relative;
}

@mixin checkbox {
  position: relative;

  &-input {
    position: absolute;
    opacity: 0;
    width: 18px;
    height: 18px;
  }

  &-label {
    display: flex;
    font-size: $font-size-small;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      transition: background-color 0.15s ease, border-color 0.15s ease;
      border: 1px solid $form-control-border-color;
      margin-right: 15px;
      background: #fff;
      border-radius: 100%;
    }
  }

  &-input[aria-invalid='true'] + &-label::before {
    border-color: $color-error;
  }

  &-input:checked + &-label::before {
    border-color: transparent;
    background:
      svg-load('../assets/icons/check.svg', fill=#fff) 45% 45% / 66%
      no-repeat,
      $color-brand;
  }

  &-input:disabled + &-label {
    opacity: 0.5;
  }

  &-input:focus-visible + &-label::before {
    outline: 2px dashed $color-brand;
    outline-offset: 0.2em;
  }
}

.CheckboxList {
  $comp: &;

  &-item {
    border-bottom: 1px solid $color-slate-10;

    &:nth-last-child(1) {
      border-bottom: none;
    }

    @include checkbox();
    padding-bottom: 8px;

    & + & {
      margin-top: 8px;
      padding-bottom: 8px;
    }
  }

  // Modifiers

  &--striped {
    #{$comp}-item {
      padding: 10px 10px 10px 0;

      // &:nth-child(odd) {
      //   background: $color-limestone-20;
      // }
    }

    #{$comp}-item + #{$comp}-item {
      margin-top: 0;
    }
  }
}

.Checkbox {
  @include checkbox();
}

.Select {
  height: $btn-height;
  font-size: $font-size-small;
  border-radius: 10px;
  border: 1px solid $form-control-border-color;
  appearance: none;
  background:
    svg-load('../assets/icons/chevron-down.svg', fill=$color-base)
    no-repeat;
  background-size: 14px;
  background-position: calc(100% - 10px) 50%;
  padding: 0 30px 0 10px;
  font-family: inherit;
  display: block;
  width: 100%;
  color: $color-base;
  font-weight: normal;

  &--sm {
    height: 32px;
    font-size: $font-size-small;
  }

  &--lg {
    height: 50px;
  }

  &--locked {
    background: svg-load('../assets/icons/lock.svg', fill=$color-base) no-repeat;
    background-size: 14px;
    background-position: calc(100% - 10px) 50%;
  }
}

select[readonly] option,
select[readonly] optgroup {
  display: none;
}

.RadioToggleBar {
  display: flex;
  gap: 20px;

  &-item {
    position: relative;
    flex: 1;
  }

  &-input {
    position: absolute;
    opacity: 0;
  }

  &-label {
    display: flex;
    justify-content: center;
    font-size: $font-size-small;
    font-weight: normal;
    letter-spacing: 0.05em;
    cursor: pointer;
    line-height: 1.3;
    padding: 10px;
    color: $color-slate;
    border-radius: 30px;
    background-color: #f0f3f5;
  }

  &-input:checked + &-label {
    background: $color-slate;
    color: #fff;

    &::before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      margin-right: 8px;
      background:
        svg-load('../assets/icons/check.svg', fill=#fff) 50% 50% /
        100% no-repeat,
        $color-slate;
    }
  }
}
