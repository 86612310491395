.SiteGuideIntro {
  margin: -40px 0 40px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $color-limestone-60;
  padding-bottom: 30px;
  
  @include media-breakpoint-up(medium) {
    margin-top: -140px;
    padding-bottom: 0;
    border-bottom: 0;
    background: $color-limestone-20;
    flex-direction: row;
  }

  @include media-breakpoint-up(medium) {
    margin-top: -140px;
    background: $color-limestone-20;
    flex-direction: row;
  }

  &-body {
    flex: 0 0 percentage(7/12);
    font-size: $font-size-small;

    @include media-breakpoint-up(medium) {
      padding: 40px;
    }
  }

  &-heading {
    @include h3;
    @include media-breakpoint-up(medium) {
      margin-bottom: 1.5em;
    }
  }

  &-footer {
    @include media-breakpoint-up(medium) {
      border-top: 1px solid $border-color;
      padding-top: 30px;
      margin-top: 30px;
    }
  }

  &-cta {
    font-size: $font-size-large;
    font-weight: bold;
  }

  &-media {
    margin-bottom: 20px;
    
    @include media-breakpoint-up(medium) {
      position: relative;
      order: 1;
      flex: 0 0 percentage(5/12);
      margin-bottom: 0;
    }
  }

  &-img {
    width: 100%;

    @include media-breakpoint-up(medium) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}