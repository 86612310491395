.TabContainer {
  margin-bottom: betterClamp(50px, 100px);
}

.TabList {
  display: flex;

  @include media-breakpoint-down(medium) {
    display: none;
  }

  &-tab {
    position: relative;
    cursor: pointer;
    color: $color-slate-70;
    font-size: $font-size-small;
    font-weight: 800;
    font-style: italic;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding: 4px 24px;
    transition: background 0.15s ease, color 0.15s ease;
    mask-image:
      linear-gradient(
        -60deg,
        transparent 13px,
        rgba(0, 0, 0, 1) 13px,
        rgba(0, 0, 0, 1) calc(100% - 13px),
        transparent calc(100% - 13px)
      );
    
    &:hover,
    &:active {
      color: $color-brand;
    }
    
    &[aria-selected='true'] {
      color: #fff;
      background: $color-slate-70;
    }

    & + & {
      margin-left: -14px;
    }
  }
}

.TabPanels {
  position: relative;
}

.TabPanel {
  @include media-breakpoint-down(medium) {
    margin-top: -1px;
  }

  &-toggle {
    @include accordion-toggle;

    display: none;
    width: calc(100% + #{betterClamp($page-margin-small * 2, $page-margin-large * 2)});
    margin-left: calc(-1 * #{betterClamp($page-margin-small, $page-margin-large)});
    margin-right: calc(-1 * #{betterClamp($page-margin-small, $page-margin-large)});
    padding-left: betterClamp($page-margin-small, $page-margin-large);
    padding-right: betterClamp($page-margin-small, $page-margin-large);
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    background: $color-slate-5;

    &[aria-expanded='true'] {
      background: $color-slate-90;
      color: #fff;
    }

    @include media-breakpoint-down(medium) {
      display: flex;
    }
  }

  &-content {
    @include media-breakpoint-down(medium) {
      @include accordion-panel;
    }
  }

  &-contentInner {
    padding: betterClamp(20px, 40px) 0;

    @include media-breakpoint-up(medium) {
      padding-bottom: 0;
    }
  }
}

