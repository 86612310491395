.Grid {
  $comp: &;

  --grid-gap: #{$grid-gap};
  
  display: grid;
  column-gap: $grid-gap;
  row-gap: betterClamp($grid-gap * 1.5, $grid-gap * 2);

  @media print {
    grid-template-columns: 1fr 1fr;
  }

  &-item {
    // & > *:last-child {
    //   margin-bottom: 0;
    // }
  }

  // Modifiers

  &--condensed {
    row-gap: $grid-gap;
  }

  &--spaced {
    @include media-breakpoint-up(large) {
      column-gap: betterClamp($grid-gap, $grid-gap * 3);
    }
  }

  // Bordered

  &--separator {
    column-gap: betterClamp($grid-gap, $grid-gap * 2);
    padding-bottom: 1px;
    padding-left: betterClamp($grid-gap / 2, $grid-gap);
    padding-right: betterClamp($grid-gap / 2, $grid-gap);
    
    #{$comp}-item {
      position: relative;

      &::before,
      &::after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background: var(--border-color, $border-color-solid);
        top: 0;
        position: absolute;
      }

      &::before {
        left: calc(-1 * #{betterClamp($grid-gap / 2, $grid-gap)});
        margin-left: -1px;
      }

      &::after {
        right: calc(-1 * #{betterClamp($grid-gap / 2, $grid-gap)});
      }
    }

    &#{$comp}--spaced {
      @include media-breakpoint-up(medium) {
        #{$comp}-item::before {
          left: -($grid-gap * 3) / 2;
        }
      }
    }
  }

  // Scroller

  &--scroller {
    @include media-breakpoint-down(large) {
      margin: 0 calc(-1 * #{betterClamp($page-margin-small, $page-margin-large)});
      padding: 0 betterClamp($page-margin-small, $page-margin-large);
      overflow-y: hidden;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      grid-auto-flow: column;
      grid-auto-columns: calc(50% - #{$grid-gap * 2});
      grid-template-columns: none;

      > * {
        scroll-snap-align: center;
      }
    }

    @include media-breakpoint-down(medium) {
      grid-auto-columns: calc(100% - #{$page-margin-small * 2});
    }
  }

  &--manual {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(12, 1fr);
      grid-auto-flow: dense;
    }

    @media print {
      grid-template-columns: repeat(12, 1fr);
      grid-auto-flow: dense;
    }
  }

  // Columns

  &--2col {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--3col {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(large) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--4col {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(large) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--5col {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(large) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  // Extra small

  &--xs2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &--xs3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &--xs4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &--xs6 {
    grid-template-columns: repeat(6, 1fr);
  }

  // Small

  &--sm2 {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--sm3 {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--sm4 {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--sm5 {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &--sm6 {
    @include media-breakpoint-up(small) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  // Medium

  &--md2 {
    @include media-breakpoint-up(medium) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--md3 {
    @include media-breakpoint-up(medium) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--md4 {
    @include media-breakpoint-up(medium) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--md5 {
    @include media-breakpoint-up(medium) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &--md6 {
    @include media-breakpoint-up(medium) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  // Large

  &--lg2 {
    @include media-breakpoint-up(large) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--lg3 {
    @include media-breakpoint-up(large) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--lg4 {
    @include media-breakpoint-up(large) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--lg5 {
    @include media-breakpoint-up(large) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &--lg6 {
    @include media-breakpoint-up(large) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  // Extra large

  &--xl2 {
    @include media-breakpoint-up(xlarge) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--xl3 {
    @include media-breakpoint-up(xlarge) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &--xl4 {
    @include media-breakpoint-up(xlarge) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &--xl5 {
    @include media-breakpoint-up(xlarge) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  &--xl6 {
    @include media-breakpoint-up(xlarge) {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}

[class*='Grid-item--sm'],
[class*='Grid-item--md'],
[class*='Grid-item--lg'],
[class*='Grid-item--xl'] {
  grid-column-end: span 12;
  min-width: 0;

  @media print {
    grid-column-end: span 6;
  }
}

@mixin create-grid-items( $breakpoint: large ) {
  @include media-breakpoint-up($breakpoint) {
    @for $i from 1 through 12 {
      .Grid-item--#{map-get($grid-classes, $breakpoint)}#{$i} {
        grid-column-end: span $i;
      }
    }

    @for $i from 1 through 12 {
      .Grid-item--#{map-get($grid-classes, $breakpoint)}Offset#{$i} {
        grid-column-start: $i + 1;
      }
    }
  }
}

@include create-grid-items( small );
@include create-grid-items( medium );
@include create-grid-items( large );
@include create-grid-items( xlarge );
