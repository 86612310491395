.FeedbackSection {
  $comp: &;
  
  background: $color-limestone-20;
  padding: betterClamp(40px, 100px) 0;

  &-body {
    font-size: $font-size-small;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &-rating {
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

    @include media-breakpoint-down(small) {
      justify-content: space-between;
    }
  }

  &-ratingLabel {
    color: $color-base;
    margin: 0 10px 0 0;
    font-weight: normal;
    font-size: $font-size-small;

    [aria-invalid='true'] & {
      color: $color-error;
    }

    @include media-breakpoint-up(large) {
      font-size: $font-size-base;
      font-weight: bold;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
  }

  &-btn {
    @extend .Link;
    @extend .Link--sm;
  }

  &-successHeading {
    color: $color-slate;
  }

  &-successMessage {
    font-size: $font-size-small;
  }
}