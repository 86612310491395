@font-face {
  font-family: 'GoodHome';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url('/src/fonts/goodhome-regular.woff2') format('woff2'),
    url('/src/fonts/goodhome-regular.woff') format('woff'),
    url('/src/fonts/goodhome-regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GoodHome';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  src:
    url('/src/fonts/goodhome-bold.woff2') format('woff2'),
    url('/src/fonts/goodhome-bold.woff') format('woff'),
    url('/src/fonts/goodhome-bold.ttf') format('truetype');
}

@font-face {
  font-family: 'GoodHome';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src:
    url('/src/fonts/goodhome-light.woff2') format('woff2'),
    url('/src/fonts/goodhome-light.woff') format('woff'),
    url('/src/fonts/goodhome-light.ttf') format('truetype');
}