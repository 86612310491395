.Basic {
  position: relative;
}

.BasicHeader {
  $comp: &;

  position: relative;
  z-index: 1;
  padding-bottom: 1px;

  &-inner {
    @include container;
    position: relative;
    z-index: 1;
    padding: 40px 0;
  }

  &-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: $color-base;
    height: 250px;

    img {
      opacity: 0.3;
    }

    @include media-breakpoint-up(large) {
      height: 100%;
      padding: 0;
    }
  }

  &-title {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  &-titleLogo {
    width: 148px;
    display: block;
  }

  &-titleLabel {
    color: $color-slate-40;
    font-weight: normal;
    font-size: betterClamp(20px, 26px);
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid $color-slate-20;

  }

  // Modifiers

  .Basic--v2 & {
    background: $color-slate-80;

    #{$comp}-inner {
      padding-top: betterClamp(40px, 60px);
      padding-bottom: betterClamp(100px, 120px);
      position: relative;
      height: 330px;
    }

    #{$comp}-titleLabel {
      color: #fff;
      border-left: 1px solid rgb(#fff, 0.33);
    }
  }
}

.BasicBody {
  $comp: &;

  background-color: $color-limestone-20;
  margin: 0;
  z-index: 2;
  padding: 40px 0 80px;

  @include media-breakpoint-down(large) {
    padding: 0;
  }

  &-header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 50px;
    padding: 0 20px;
    background-color: $color-limestone-60;
    color: $color-slate;
    display: flex;
    align-items: center;
  }

  &-headerIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 15px;
  }

  &-headerDate {
    color: $color-slate-40;
    font-size: 14px;
    margin-left: auto;
  }

  &-inner {
    background-color: white;
    padding: betterClamp(50px, 80px) betterClamp($page-margin-small, 90px);
    padding-top: 100px;
    max-width: 880px;
    position: relative;
    z-index: 2;
    margin: 0 auto;

    h1 {
      font-size: 30px;
  
      &::after {
        content: '';
        display: block;
        width: 42px;
        height: 2px;
        background-color: $color-slate-20;
        margin: 40px 0;
      }
    }
  
    h2 {
      font-size: 22px;
      line-height: 30px;
      border-bottom: 1px solid $border-color;
      padding-bottom: 20px;
      margin: 30px auto;
  
      span {
        color: $color-moss;
      }
    }
  
    table {
      margin: 40px auto;
      border-collapse: collapse;
      width: 100%;
      border-spacing: 30px;
  
      tr {
        border-top: 1px solid $color-limestone-60;
  
        &:nth-child(1) {
          border-top: none;
        }
      }
  
      th {
        vertical-align: top;
        text-align: left;
      }
  
      td {
        vertical-align: top;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
      }
  
      td:nth-child(1) {
  
        font-weight: 800;
        width: 33%;
      }
    }

    .Lead {
      color: $color-slate;
      margin-bottom: 15px;
    }
  }

  // Modifiers

  .Basic--v2 & {
    padding: 0;
    padding-bottom: 80px;

    #{$comp}-inner {
      margin-top: -180px;
    }

    #{$comp}-Header {
      background-color: $color-slate;
      color: #fff;
    }
  }
}

.Download {
  font-size: 1em;
  background-color: $color-limestone-60;
  padding: 20px;
  margin: 30px 0;
  display: flex;
  align-items: center;

  &-icon {
    fill: $color-slate-40;
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }

  &-text {
    color: $color-slate-80;
    justify-self: left;
  }

  &-link {
    color: $color-vivid-jade;
    font-weight: bold;
    margin-left: auto;
  }
}