@import './mixins/breakpoints';
@import './mixins/fluid';

@mixin container() {
  margin: 0 auto;
  width: calc(100% - 5vw);
  max-width: 1250px;
  padding: 0 betterClamp($page-margin-small, $page-margin-large);
}

@mixin container-fixed() {
  margin: 0 auto;
  width: $grid-container-width;
  padding: 0 $page-margin-large;
}

@mixin custom-scrollbar() {
  --scrollbarBG: transparent;
  --thumbBG: #{$color-slate-30};

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}

@mixin scroll-lock {
  // when modal active
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  // Other browsers 
  overscroll-behavior: none;
}

@mixin sr-only {
  position: absolute;
  right: 100%;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  appearance: none;
}