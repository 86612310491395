.NavList {
  @extend %list-unstyled;

  max-width: 520px;

  &-item {
    border-top: 1px solid $border-color;
    margin-top: 12px;
    padding-top: 12px;
  }

  &-link {
    display: flex;
    justify-content: space-between;
    line-height: 1.3;
    font-size: $font-size-small;
  }

  &-icon {
    margin-left: auto;
    flex: none;
    font-size: $font-size-large;
    margin-left: 10px;
  }
}