.SearchResultsTabs {
  @extend %list-unstyled;

  @include tab-container();

  @include media-breakpoint-up(medium) {
    padding-right: $page-margin-small;
    padding-left: $page-margin-small;
  }

  &-tab {
    @include tab();
  }
}
