.Announcement {
  border-bottom: 1px solid $color-limestone-60;
  border-top: 1px solid $color-limestone-60;
  padding: 15px 0 30px;
  margin-top: -1px;
  font-size: $font-size-small;
  display: grid;
  grid-template-columns: 7fr max-content;
  grid-template-areas:
    '. time'
    '. .';
  gap: $grid-gap;

  &-title {
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  &-icon {
    font-size: 24px;
    margin-right: 8px;
  }

  &-date {
    font-size: 12px;
    color: $color-slate-40;
    grid-area: time;
    justify-self: end;
  }

  &-body {
    @include media-breakpoint-down(small) {
      grid-column-start: 2 span;
    }
  }
}