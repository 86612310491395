.SearchFilter {
  position: relative;
  padding: 10px 0;

  & + & {
    border-top: 1px solid $color-limestone-60;
  }

  &-input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
  }

  &-label {
    $toggle-width: 40px;
    $toggle-height: 22px;
    $switch-size: 18px;
    $toggle-padding: ($toggle-height - $switch-size) / 2;
    
    @debug $toggle-padding;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    padding-right: 50px;
    font-size: $font-size-small;

    &::before {
      content: '';
      display: block;
      flex: none;
      margin-left: 10px;
      width: $toggle-width;
      height: $toggle-height;
      border-radius: $toggle-height / 2;
      background: $color-limestone-80;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color 0.15s ease;
    }

    &::after {
      content: '';
      position: absolute;
      right: $toggle-width - $switch-size - $toggle-padding;
      top: $toggle-padding;
      display: block;
      width: $switch-size;
      height: $switch-size;
      border-radius: 50%;
      background: #fff;
      z-index: 1;
      transition: transform 0.15s ease;
    }

    input:checked + &::before {
      background: $color-vivid-jade;
    }

    input:checked + &::after {
      transform: translateX(100%);
    }
  }
}