[role='tabpanel'] {
  &[aria-hidden='true'] {
    display: none;
  }
}

@mixin tab-container() {
  @extend %list-unstyled;

  padding: 16px betterClamp($page-margin-small, 50px) 0;
  background: $color-limestone-60;
  display: flex;
  flex: 1;
  align-self: stretch;

  @include media-breakpoint-down(large) {
    margin: 0 calc(-1 * #{betterClamp($page-margin-small, $page-margin-large)});
    padding-left: betterClamp($page-margin-small, $page-margin-large);
    padding-right: betterClamp($page-margin-small, $page-margin-large);
  }
}

@mixin tab() {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.3;
  font-weight: bold;
  font-size: $font-size-small;
  color: #fff;
  background: $color-slate;
  padding: 10px betterClamp(15px, 25px);
  border-radius: 4px 4px 0 0;
  transition: background 0.15s ease, color 0.15s ease;

  &:hover,
  &:active {
    background: $color-brand;
    color: #fff;
  }

  &[aria-selected='true'] {
    background: #fff;
    color: $color-slate;
  }

  & + & {
    margin-left: 8px;
  }

  @include media-breakpoint-down(large) {
    flex: 1;
  }
}