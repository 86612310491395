.Image {
  height: 0;
  padding-bottom: var(--height);
  position: relative;
  background: $color-slate-15;

  &-img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
