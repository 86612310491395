@mixin accordion-panel {
  transition-property: max-height, visibility, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
  max-height: var(--max-height, 100%);
  overflow: hidden;
  opacity: 1;

  &[hidden] {
    max-height: 0;
    visibility: hidden;
    display: block;
    opacity: 0;
    transition-timing-function: ease-out;
  }
}

@mixin accordion-toggle($color: null, $icon-color: $color-brand) {
  @if $color {
    --heading-color: #{$color};
  }

  @extend %heading;
  font-size: betterClamp($font-size-base, $font-size-large);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 26px 0;
  transition: background-color 0.15s ease;

  &::after {
    content: '';
    display: block;
    flex: none;
    margin-top: -0.05em;
    margin-left: 10px;
    width: betterClamp(18px, 28px);
    height: betterClamp(18px, 28px);
    background: svg-load('../assets/icons/plus-thin.svg', fill=$icon-color) 50% 50% / 100% auto no-repeat;
  }

  &[aria-expanded='true']::after {
    background-image: svg-load('../assets/icons/minus-thin.svg', fill=$icon-color);
  }
}

.Accordion {
  margin-bottom: betterClamp(40px, 100px);

  &-item {
    border-top: 1px solid $border-color;
  }

  &-toggle {
    @include accordion-toggle();
  }

  &-panel {
    @include accordion-panel;
  }

  &-panelInner {
    padding: 0 0 20px;
  }
}

@keyframes fadeIn {
  0% {
    max-height: 0;
    visibility: hidden;
  }

  100% {
    max-height: 200px;
    visibility: visible;
  }
}