.Table {
  width: 100%;
  line-height: 1.3;
  text-align: left;
  border-collapse: collapse;
  text-wrap: pretty;

  thead a {
    color: inherit;

    &.is-active {
      color: $color-base;
    }
  }

  &.mw600 {
    max-width: 600px;
  }

  .ctr {
    text-align: center;
  }
  .disable {
    color: $color-slate-40;
    margin: 0;
  }
  td.icon a svg {
    height: 1.15em;
    width: 1.15em;
    width: 26em;
  }

  &.VrTab {
    margin-bottom: 40px;

    tbody {
      font-size: 13px;

      tr th,
      tr td {
        padding: 8px 0;
        font-weight: normal;
        color: $color-slate-80;
      }
      tr > th:first-child,
      tr > td:first-child {
        width: 200px;
      }
    }
  }

  &.VrTab thead {
    tr th {
      padding: 8px 0;
    }
  }

  th.multiple,
  td.multiple {
    padding-left: 35px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 13px;
      top: 7px;
      background-image:
        svg-load(
          '../assets/icons/multiple-2.svg',
          fill=$color-slate-40
        );
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  @include media-breakpoint-down(medium) {
    &,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead {
      @include sr-only;
    }

    tr {
      border-top: 1px solid $border-color;
      padding: 17px 0;

      &:last-child {
        padding-bottom: 0;
      }
    }

    th,
    td {
      font-size: $font-size-small;
      padding: 3px 0;
      word-break: break-all;

      &[data-title] {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: $grid-gap;
      }
    }

    [data-title]::before,
    [data-title]::before {
      content: attr(data-title);
      font-size: 11px;
      font-weight: normal;
      color: $color-slate-40;
      word-break: normal;
    }
  }

  @include media-breakpoint-up(medium) {
    thead {
      font-size: 12px;
    }

    tbody {
      font-size: 13px;
    }

    thead > tr > th {
      border-bottom: 1px solid $border-color;
      font-weight: normal;
      padding: 0 12px 8px;
      color: $color-slate-40;
    }

    tbody > tr > th,
    tbody > tr > td {
      padding: 8px 12px 6px;
      text-wrap: nowrap;
      max-width: 240px;
    }

    thead > tr > th,
    tbody > tr > th,
    tbody > tr > td {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    tbody > tr > th {
      color: $color-base;
    }

    tbody > tr + tr > td,
    tbody > tr + tr > th {
      border-top: 1px solid $border-color;
    }

    tr.collapse-border > td,
    tr.collapse-border > th {
      border-top: none;
    }
  }

  &-actions {
    // font-weight: bold;
    white-space: nowrap;
    text-align: right;

    button,
    a {
      white-space: nowrap;

      &:not(:last-child) {
        border-right: 1px solid $border-color;
        padding-right: 10px;
        margin-right: 10px;
      }
    }

    button {
      color: var(--link-color, $color-link);
      font-weight: inherit;
      transition:
        color 0.15s ease,
        text-decoration 0.15s ease,
        text-underline-offset 0.15s ease;

      &:hover,
      &:active {
        color: var(--link-hover-color, $color-link);
      }
    }

    &.red a,
    &.red {
      color: $color-deep-canyon;
    }

    @include media-breakpoint-up(medium) {
      justify-content: flex-end;
    }
  }

  // Modifiers

  &--condensed {
    tbody > tr > th,
    tbody > tr > td {
      padding: 8px 12px;
    }
  }

  &--first-col-250 {
    tbody > tr > th:first-child,
    tbody > tr > td:first-child {
      width: 250px;
    }
  }

  &--VLR {
    tbody > tr > th,
    tbody > tr > td {
      padding: 12px;
      .Chip:only-child {
        margin-bottom: 0;
      }
    }
  }

  .no-bdr {
    border: none;
  }
  span.red {
    color: $color-deep-canyon;
  }
}
