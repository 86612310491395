//
// Typography
//

.u-font-size-sm {
  font-size: $font-size-small;
}

.u-font-size-lg {
  font-size: $font-size-large;
}

.u-columns-2 {
  columns: 2;
  column-gap: $grid-gap;
}

.u-text-center {
  text-align: center;
}

//
// Visibilty
//

.u-visible {
  visibility: visible;
  height: auto;
}

.u-collapse {
  visibility: collapse;
  height: 0;
}

.u-hidden-xs-max {
  @include media-breakpoint-down(small) {
    display: none !important;
  }
}

.u-hidden-sm-max {
  @include media-breakpoint-down(medium) {
    display: none !important;
  }
}

.u-hidden-md-max {
  @include media-breakpoint-down(large) {
    display: none !important;
  }
}

.u-hidden-sm-min {
  @include media-breakpoint-up(small) {
    display: none !important;
  }
}

.u-hidden-md-min {
  @include media-breakpoint-up(medium) {
    display: none !important;
  }
}

.u-hidden-lg-min {
  @include media-breakpoint-up(large) {
    display: none !important;
  }
}

.u-hidden-xlg-min {
  @include media-breakpoint-up(xlarge) {
    display: none !important;
  }
}

.u-sr-only {
  @include sr-only;
}

// Layout

.u-d-block,
.d-block {
  display: block !important;
}

.u-d-none,
.d-none {
  display: none !important;
}

.u-d-flex,
.d-flex {
  display: flex !important;
}

.u-d-inlineblock,
.d-inlineblock {
  display: inline-block !important;
}

//
// Spacing
//

.u-m-0 {
  margin: 0 !important;
}

@for $i from 0 through 10 {
  // Margin top increments of 10
  .u-mt-#{$i * 10} {
    margin-top: ($i * 10px) !important;
  }

  // Margin bottom increments of 10
  .u-mb-#{$i * 10} {
    margin-bottom: ($i * 10px) !important;
  }

  // Margin top increments of 10
  .u-mr-#{$i * 10} {
    margin-right: ($i * 10px) !important;
  }

  // Margin bottom increments of 10
  .u-ml-#{$i * 10} {
    margin-left: ($i * 10px) !important;
  }
}

.u-p-0 {
  padding: 0 !important;
}

@for $i from 0 through 10 {
  // Margin top increments of 10
  .u-pt-#{$i * 10} {
    padding-top: ($i * 10px) !important;
  }

  // Margin bottom increments of 10
  .u-pb-#{$i * 10} {
    padding-bottom: ($i * 10px) !important;
  }

  // Margin top increments of 10
  .u-pr-#{$i * 10} {
    padding-right: ($i * 10px) !important;
  }

  // Margin bottom increments of 10
  .u-pl-#{$i * 10} {
    padding-left: ($i * 10px) !important;
  }
}

//
// Alignment
//

.u-container {
  @include container;
}

.u-container-fixed {
  @include container-fixed;
}

@mixin create-justify-utils($breakpoint) {
  $positions: center, start, end, flex-start, flex-end, space-between,
    space-around, space-evenly, stretch;

  @include media-breakpoint-up($breakpoint) {
    @each $value in $positions {
      .u-justify-content-#{$value}-#{map-get($grid-classes, $breakpoint)} {
        justify-content: $value !important;
      }

      .u-justify-self-#{$value}-#{map-get($grid-classes, $breakpoint)} {
        justify-self: $value !important;
      }
    }
  }
}

@mixin create-align-utils($breakpoint) {
  $positions: center, start, end, flex-start, flex-end, baseline, stretch;

  @include media-breakpoint-up($breakpoint) {
    @each $value in $positions {
      .u-align-items-#{$value}-#{map-get($grid-classes, $breakpoint)} {
        align-items: $value !important;
      }

      .u-align-self-#{$value}-#{map-get($grid-classes, $breakpoint)} {
        align-self: $value !important;
      }
    }
  }
}

@each $breakpoint in xsmall, small, medium, large {
  @include create-justify-utils($breakpoint);
  @include create-align-utils($breakpoint);
}
.u-align-self-center {
  align-self: center;
}

.u-sticky-lg {
  @include media-breakpoint-up(large) {
    position: sticky !important;
    top: calc(#{betterClamp(50px, 100px)} + var(--scroll-offset));
  }
}

.u-scroll-lock {
  @include scroll-lock;
}

.u-hide-on-expand {
  &[aria-expanded='true'] {
    display: none;
  }
}

@for $i from 0 through 10 {
  .u-flex-gap-#{$i * 10} {
    gap: ($i * 10px) !important;
  }
}

// Utility class for display: flex;
.u-flex {
  display: flex !important;
}
// Utility class for gap
@for $i from 0 through 10 {
  .u-flex-gap-#{$i * 10} {
    gap: ($i * 10px) !important;
  }
}

// Utility classes for justify-content
.u-justify-content-flex-start {
  justify-content: flex-start !important;
}

.u-justify-content-flex-end {
  justify-content: flex-end !important;
}

.u-justify-content-center {
  justify-content: center !important;
}

.u-justify-content-space-between {
  justify-content: space-between !important;
}

.u-justify-content-space-around {
  justify-content: space-around !important;
}

.u-justify-content-space-evenly {
  justify-content: space-evenly !important;
}

// Utility classes for align-items
.u-align-items-flex-start {
  align-items: flex-start !important;
}

.u-align-items-flex-end {
  align-items: flex-end !important;
}

.u-align-items-center {
  align-items: center !important;
}

.u-align-items-baseline {
  align-items: baseline !important;
}

.u-align-items-stretch {
  align-items: stretch !important;
}

// Utility classes for align-self
.u-align-self-flex-start {
  align-self: flex-start !important;
}

.u-align-self-flex-end {
  align-self: flex-end !important;
}

.u-align-self-center {
  align-self: center !important;
}

.u-align-self-baseline {
  align-self: baseline !important;
}

.u-align-self-stretch {
  align-self: stretch !important;
}

//
// Project specific
//

.u-content {
  > *:last-child {
    margin-bottom: 0;
  }
}

.u-separator {
  border-bottom: 1px solid $border-color !important;
  padding-bottom: betterClamp(20px, 30px) !important;
  margin-bottom: betterClamp(20px, 30px) !important;
}

.u-content-indent {
  padding: 0 20px;
  margin: 0 auto;
  max-width: $grid-container-width;
  @include media-breakpoint-up(large) {
    padding: 0 percentage(1/12);
  }
   
}

.u-content-indent-lg {
  @include media-breakpoint-up(large) {
    padding: 0 percentage(1/12);
  }
}

.u-icon-xl {
  font-size: 120px;
  display: inline-block;
  margin-bottom: 20px;
  color: $color-moss-80;
}

span.popup {
  position: relative;
  cursor: pointer;
}

span.popup::before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
  padding: 10px;
}

span.popup:hover::before {
  opacity: 1;
  visibility: visible;
}
