.SiteGuideHeader {
  background: $color-limestone-40;
  padding-bottom: 40px;

  @include media-breakpoint-up(medium) {
    padding-bottom: 140px;
  }

  &-inner {
    @include container;
  }

  &-header {
    padding: betterClamp(20px, 50px, $minWidth: breakpoint(small)) 0 betterClamp(20px, 30px, $minWidth: breakpoint(small));

    @include media-breakpoint-up(medium) {
      display: flex;
      justify-content: space-between;
    }
  }

  &-heading {
    margin-bottom: 0.5em;

    @include media-breakpoint-up(medium) {
      margin: 0;
    }
  }

  &-help {
    display: flex;
    

    @include media-breakpoint-up(medium) {
      margin-left: 20px;
      align-items: center;
    }
  }

  &-helpLabel {
    font-size: betterClamp(12px, $font-size-small);
    margin: 0;
    line-height: 1.3;

    a {
      font-size: betterClamp($font-size-small, $font-size-large);
      font-weight: bold;
      display: block;
    }

    @include media-breakpoint-up(medium) {
      text-align: right;
    }
  }

  &-helpIcon {
    background: $color-morning-grass;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: $color-base;
    font-size: 14px;
    border-radius: 50%;
    margin-right: 12px;

    @include media-breakpoint-up(medium) {
      order: 1;
      margin-right: 0;
      margin-left: 12px;
      width: 35px;
      height: 35px;
      font-size: 24px;
    }
  }
}