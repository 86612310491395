.NotificationBar {
  position: relative;
  background: $color-brand;
  padding: 20px 20px 20px 65px;
  color: #fff;
  line-height: 1.3;
  margin-bottom: 30px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 50px;
    top: 20px;
    bottom: 20px;
    border-right: 1px solid $color-vivid-jade-60;
  }

  @include media-breakpoint-up(medium) {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    margin-bottom: 0;
  }

  &-title {
    flex: none;
    display: flex;
    align-items: center;
    font-size: $font-size-small;
    margin-bottom: 0;
    color: $color-vivid-jade-40;
    
    @include media-breakpoint-up(medium) {
      border-right: 1px solid $color-vivid-jade-60;
      padding-right: $grid-gap;
      margin-right: $grid-gap;
      font-size: $font-size-base;
      color: inherit;
    }
  }

  &-icon {
    background: $color-deep-canyon;
    border-radius: 50%;
    font-size: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-right: 12px;
    
    @include media-breakpoint-down(medium) {
      position: absolute;
      top: 20px;
      left: 15px;
      width: 20px;
      height: 20px;
      font-size: 12px;
    }
  }

  &-message {
    font-weight: bold;
    flex: 1;

    a {
      color: inherit;
      text-decoration: underline;
    }

    @include media-breakpoint-up(medium) {
      margin-right: $grid-gap;
    }
  }

  &-date {
    margin-left: auto;
    font-size: $font-size-small;
    opacity: 0.66;

    @include media-breakpoint-down(medium) {
      display: none;
    }
  }
  
}