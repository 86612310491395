.imp {
  position: fixed;
  inset: 0;
  z-index: 100;
  font-size: 14px;
  pointer-events: none;
  .imp-bdr {
    position: absolute;
    inset: 0;
    height: 100%;
    border: 16px solid $color-morning-grass;
    border-top: 0;
    z-index: 0;
  }
  .imp-bar {
    pointer-events: all;
    background-color: $color-morning-grass;
    min-height: 38px;
    top: 0;
    z-index: 100;
    text-align: center;
    padding: 0 20px;
    .return {
      position: absolute;
      left: 0;
      top: 0;
      background-color: $color-vivid-jade;
      height: 38px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      .Icon {
        margin-right: 5px;
      }
    }
    p {
      display: inline-block;
      margin: 0;
      padding-top: 8px;
      color: $color-slate-80;
      text-align: center;
      span {
        color: $color-slate;
        font-weight: bold;
      }
      
    }
    div.email {
      color: $color-slate;
      font-weight: bold;
      display: inline-block;
    }
    .profile-icon {
      margin-right: 10px;
      padding: 1px 3px;
      border-radius: 2px;
      background-color: $color-slate;
      margin-left: 10px;
      .Icon {
        width: 12px;
        color: $color-morning-grass;
      }
    }
    a.close {
      position: absolute;
      right: 0;
      top: 0;
      padding: 8px 46px 8px;
      color: $color-slate-80;
      .Icon {
        display: block;
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        height: 38px;
        width: 38px;
        padding: 12px;
        color: white;
        background: $color-deep-canyon;

      }
    }
  }

  @include media-breakpoint-down(medium) {
    font-size: 10px;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #dfd62b;
      position: absolute;
      top: 37px;
    }
    .imp-bdr {
      border: 8px solid #eee52a;
    }
    .imp-bar {
      min-height: 73px;
    }
    .imp-bar p {
      padding-top: 48px;
    }
  }
}

.imp-spacer {
  height: 38px;
  @include media-breakpoint-down(medium) {
    height: 72px;
  }
}

