.Modal,
.Modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.Modal {
  $comp: &;

  z-index: 200;
  display: flex;
  padding: 66px 0;
  overflow-y: auto;
  transition: visibility 0.15s ease, opacity 0.15s ease;

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
  }

  &-close {
    color: $color-link;
    font-size: 16px;
    position: absolute;
    right: 25px;
    top: 30px;
    z-index: 3;

    svg {
      display: block;
    }
  }

  &-overlay {
    background-color: rgba(43, 46, 56, 0.7);
  }

  &-title {
    @include h2;
    margin-bottom: 30px;
  }

  &-content {
    > *:last-child {
      margin-bottom: 0;
    }
  }

  &-dialog {
    margin: auto;
    width: 595px;
    z-index: 2;
    position: relative;
    background-color: white;
    padding: 60px;
    border-radius: 16px;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

    .m-h {
      max-height: 300px;
      overflow-y: auto;
    }
  }

  &.message {
    gap: 40px;
    padding: 0;
    .Modal-dialog {
      padding: 30px;
      width: auto;
      max-width: 800px;
    }
    .Modal-close {
      display: none;
    }
    h2 {
      margin-bottom: 0.5em;
    }
    p {
      margin: 0;
    }
    p.warning {
      color: $color-deep-canyon;
    }
  }
  &.notify {
    gap: 40px;
    padding: 0;
    max-width: none;
    .Modal-dialog {
      padding: 40px 45px;
      width: auto;
      max-width: 800px;
    }
    .Modal-close {
      display: none;
    }
    h2 {
      margin-bottom: 6px;
    }
    p {
      margin: 0;
      margin-bottom: 6px;
      max-width: 35ch;
      line-height: 1.3em;
    }
    p.warning {
      color: $color-deep-canyon;
    }
    .Icon {
      width: 51px;
      height: 51px;
      &.success {
        color: $color-vivid-jade;
      }
      &.warning {
        color: $color-deep-canyon;
      }
      &.default {
        color: $color-slate-40;
      }
    }
  }
  &.ListSelect {
    gap: 40px;
    padding: 0;
    .Modal-dialog {
      padding: 50px;
      width: auto;
      min-width: 325px;
    }
    fieldset.FormRow {
      padding-top: 0;
    }
    .FormActions {
      border-top: none;
      padding: 5px 0 0;
      margin-top: 5px;
    }
  }
  p.sub {
    color: $color-slate-60;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .content-item-1 {
    margin-bottom: 0;
    justify-content: space-between;
    gap: 40px;
    display: flex;
    border-top: 1px solid $color-slate-10;
    padding-top: 20px;
    margin-bottom: 10px;

    h4 {
      margin-bottom: 5px;
    }
    p {
      font-size: 14px;
      font-weight: inherit;
    }

    &.bdr-b {
      padding-bottom: 10px;
      border-bottom: 1px solid $color-slate-10;
    }
  }

  .summary-item-1 {
    border-top: 1px solid $color-slate-10;
    padding: 20px 0;
    color: $color-slate;
    & > div {
      margin: 5px 0;
    }
    .Icon {
      width: 22px;
      height: 22px;
      margin-right: 8px;
      margin-bottom: -2px;

      &.success {
        color: $color-vivid-jade;
      }
      &.warning {
        color: $color-deep-canyon;
      }
      &.default {
        color: $color-slate-40;
      }
    }
  }
}

.content-item-2 {
  margin-bottom: 0;
  border-top: 1px solid $color-slate-10;
  padding-top: 20px;
  margin-bottom: 10px;

  h4 {
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
    font-weight: inherit;
  }

  &.bdr-b {
    padding-bottom: 10px;
    border-bottom: 1px solid $color-slate-10;
  }
}
