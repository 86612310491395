.WorkbenchNav {
  background: $color-limestone-60;
  margin-top: -1px;

  &-inner {
    @include container;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 16px;
  }

  &-tabs {
    @extend %list-unstyled;
    display: flex;
    flex: 1;
    align-self: stretch;
    
    @include media-breakpoint-up(medium) {
      margin-right: $grid-gap;
    }
  }

  &-tab {
    margin-bottom: 0;
    display: flex;

    & + & {
      margin-left: 8px;
    }

    @include media-breakpoint-down(large) {
      display: none;

      &.is-active {
        display: flex;
        margin: 0;
      }
    }

    @include media-breakpoint-down(medium) {
      flex: 1;
    }

    &--change {
      display: none;

      @include media-breakpoint-down(large) {
        display: flex;
      }
    }
  }

  &-link {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.3;
    font-weight: bold;
    font-size: $font-size-small;
    color: #fff;
    background: $color-slate;
    padding: 10px betterClamp(15px, 25px);
    border-radius: 4px 4px 0 0;
    transition: background 0.15s ease, color 0.15s ease;

    &:hover,
    &:active {
      background: $color-brand;
      color: #fff;
    }

    .is-active > & {
      background: #fff;
      color: $color-slate;
    }
  }

  &-help {
    text-align: right;
    font-weight: bold;
    font-size: $font-size-small;
    color: $color-base;
    
    a {
      text-decoration: underline;
    }

    @include media-breakpoint-down(medium) {
      display: none;
    }
  }
}