.SearchBar {
  background: $color-limestone-20;
  margin-bottom: 30px;

  &-inner {
    max-width: calc(655px + #{betterClamp($page-margin-small * 2, $page-margin-large * 2)});
    width: 100%;
    margin: 0 auto;
    padding: betterClamp(20px, 50px) betterClamp($page-margin-small, $page-margin-large);
    display: flex;
  }

  &-input {
    flex: 1 1 auto;
    height: 40px;
    border: 1px solid $color-slate-40;
    font-size: $font-size-small;
    margin-right: 10px;
    padding: 0 20px;
    min-width: 0;

    &:focus-visible {
      border-color: $color-highlight;
      outline: 0;
    }
  }
}