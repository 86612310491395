.ModalCarousel,
.ModalCarousel-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ModalCarousel {
  $comp: &;

  z-index: 200;
  display: flex;
  transition: visibility 0.15s ease, opacity 0.15s ease;

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
  }

  &-title {
    @include sr-only;
  }

  &-dialog {
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 910px;
    z-index: 2;
    padding: 0 15px;
  }

  &-close {
    background: $color-link;
    color: #fff;
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: block;
    }
  }

  &-overlay {
    background-color: rgba(53, 62, 68, 0.8);
  }

  &-title {
    @include h2;
    margin-bottom: 30px;
  }

  &-track {
    overflow: hidden;
  }
  
  &-slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;

    &--dragging {
      user-select: none;
    }
  }
    
  &-slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    a {
      user-select: none;
    }
  }
  
  &-arrows {
    -webkit-touch-callout: none;
    user-select: none;

    @include media-breakpoint-down(xlarge) {
      position: absolute;
      bottom: 4vh;
      left: 10px;
      right: 10px;
      height: 8px;
      z-index: 1;
      pointer-events: none;
    }
  }
  
  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $color-vivid-jade;
    font-size: 24px;

    &--prev {
      left: betterClamp(20px, 40px);
    }

    &--next {
      right: betterClamp(20px, 40px);
    }

    @include media-breakpoint-down(xlarge) {
      pointer-events: all;
      transform: translateY(-50%);
      font-size: 24px;

      &--prev {
        left: 0;
      }
  
      &--next {
        right: 0;
      }
    }
  }

  &-nav {
    position: absolute;
    bottom: 4vh;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    transform: translateY(50%);
  }

  &-navItem {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 betterClamp(6px, 12px);
    background: #fff;
    flex: none;

    &--active {
      background: $color-vivid-jade;
    }
  }
}
