.FeedbackForm {
  $comp: &;

  --heading-color: #{$color-slate};

  &-heading {
    color: var(--heading-color);
  }

  &-rating {
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;

    .Grid-item--lg4 & {
      justify-content: space-between;
    }

    @include media-breakpoint-down(small) {
      justify-content: space-between;
    }
  }

  &-ratingLabel {
    font-size: $font-size-small;
    font-weight: normal;
    margin: 0 10px 0 0;
    color: inherit;

    [aria-invalid='true'] & {
      color: $color-error;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
  }

  &-btn {
    @extend .Link;
    @extend .Link--sm;
  }

  &-successHeading {
    color: var(--heading-color);
  }

  &-successMessage {
    font-size: $font-size-small;
  }

  // Modifiers

  &--primary {
    --heading-color: inherit;
    --star-color: #{$color-vivid-jade-60};

    #{$comp}-heading {
      font-size: $font-size-small;
      font-weight: normal;
      margin-bottom: 15px;
    }

    #{$comp}-rating {
      justify-content: space-between;
    }

    #{$comp}-ratingLabel {
      #{$comp}-rating:not([aria-invalid='true']) & {
        color: $color-vivid-jade-40;
      }
    }

    #{$comp}-textarea {
      background: none;
      color: #fff;

      &:not([aria-invalid='true']) {
        border-color: $color-vivid-jade-60;
      }

      &::placeholder {
        color: #fff;
      }
    }
    
    #{$comp}-btn {
      background: #fff;
      padding: 0 16px;
      font-weight: bold;
      display: flex;
      align-items: center;
      height: 32px;
    }
  }
}