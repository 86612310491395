.Footer {
  background: $color-base;
  padding-left: betterClamp($page-margin-small, $page-margin-large);
  padding-right: betterClamp($page-margin-small, $page-margin-large);
  align-self: end;

  &-inner {
    @media (min-width: 1180px) {
      padding-left: betterClamp(1px, $page-margin-large, $minWidth: breakpoint(xlarge), $maxWidth: breakpoint(xxlarge));
      padding-right: betterClamp(1px, $page-margin-large, $minWidth: breakpoint(xlarge), $maxWidth: breakpoint(xxlarge));
    }
  }

  &-top {
    padding: 16px 0 0;

    @include media-breakpoint-up(large) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $border-color-inverse;
      padding: 16px 0;
    }
  }

  &-bottom {
    padding: 24px 0;
    color: $color-slate-70;

    @include media-breakpoint-up(large) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row-reverse;
    }
  }

  &-brand {
    width: betterClamp(95px, 132px);
    flex: none;
    margin: 16px $grid-gap 16px 0;

    @include media-breakpoint-up(large) {
      margin-bottom: 0;
    }
  }

  &-navItems {
    @extend %list-unstyled;

    @include media-breakpoint-up(large) {
      display: flex;
    }
  }

  &-navItem {
    border-top: 1px solid $border-color-inverse;
    margin-bottom: 0;

    @include media-breakpoint-up(large) {
      border: none;
      
      & + & {
        margin-left: betterClamp(20px, 40px);
      }
    }
  }

  &-navLink {
    font-size: $font-size-small;
    color: #fff;
    font-weight: bold;

    @include media-breakpoint-down(large) {
      padding: 10px 0;
      display: block;
    }
  }

  &-navSecondary {
    @extend %list-unstyled;
    display: flex;
    margin-bottom: 0.5em;

    @include media-breakpoint-up(large) {
      margin-left: $grid-gap * 2;
    }
  }

  &-navSecondaryItem {
    margin-right: 40px;
    margin-bottom: 0;

    @include media-breakpoint-up(large) {
      margin-right: 0;

      & + & {
        margin-left: 40px;
      }
    }
  }

  &-navSecondaryLink {
    font-size: $font-size-small;
    color: inherit;
    white-space: nowrap;
  }

  &-smallprint {
    font-size: $font-size-small;
    margin: 0;

    @include media-breakpoint-down(large) {
      margin-bottom: 1em;
    }
  }
}