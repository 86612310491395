.VideoModal,
.VideoModal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.VideoModal {
  $comp: &;

  z-index: 200;
  display: flex;

  &[aria-hidden='true'] {
    display: none;
  }

  &-title {
    @include sr-only;
  }

  &-close {
    background: $color-brand svg-load('../assets/icons/close.svg', fill=#fff) 50% 50% / 40% no-repeat;
    color: #fff;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
  }

  &-overlay {
    background-color: rgba(43, 46, 56, 0.9);
  }
  
  &-dialog {
    margin: auto;
    z-index: 2;
    position: relative;
    width: 90vw;
    height: 90vh;
    background: #000;
  }

  &-content {
    position: relative;
    width: 100%;
    height: 100%;
  }

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}




