.Header {
  $comp: &;

  border-bottom: 1px solid $color-slate-20;

  &-top {
    background: $color-limestone-20;
    padding: 10px 0;
    display: none;

    @include media-breakpoint-up(large) {
      display: block;
    }
  }

  &-brand {
    width: betterClamp(240px, 458px);

    img { display: block; }
  }

  &-topInner {
    @include container;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-signposts {
    @extend %list-unstyled;
    margin-bottom: 10px;
    
    @include media-breakpoint-up(large) {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
  }

  &-signpost {
    text-align: center;

    @include media-breakpoint-down(large) {
      padding: 12px 0;

      & + & {
        border-top: 1px solid $border-color;
      }
    }

    @include media-breakpoint-up(large) {
      margin-right: 40px;
    }
  }

  &-signpostLink {
    color: $color-slate;
    font-weight: bold;
    font-size: 15px;

    @include media-breakpoint-up(large) {
      font-size: 13px;
      color: $color-slate-60;
    }
  }

  &-signpostIcon {
    color: $color-brand;
    font-size: 16px;
  }

  &-controls {
    @extend %list-unstyled;
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  &-control {
    font-size: 12px;
    margin: 0;
    padding: 0 10px;
    color: $color-slate-60;

    & + & {
      border-left: 1px solid $border-color;
    }

    & + &--btn {
      border-left: none;
      padding-right: 0;
    }
  }

  &-controlLink {
    font-size: 13px;
    font-weight: bold;
    padding: 0 5px;
    color: $color-link;
  }

  &-bottom {
    @include media-breakpoint-down(large) {
      height: 60px;
      display: flex;
    }

    @include media-breakpoint-up(large) {
      padding: 9px 0;
      position: relative;
    }
  }

  &-bottomInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 0 betterClamp($page-margin-small, $page-margin-large);

    @include media-breakpoint-up(large) {
      @include container;
    }
  }

  &-navContainer {
    display: block;

    @include media-breakpoint-down(large) {
      transition: visibility 0.3s ease, opacity 0.3s ease;
      display: flex;
      visibility: hidden;
      opacity: 0;
      flex-direction: column;
      position: fixed;
      left: 0;
      top: 60px;
      z-index: 100;
      width: 100%;
      height: calc(100% - 60px);
      background: #fff;
      overflow-y: auto;

      &.is-expanded {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &-nav {
    @extend %list-unstyled;

    margin-bottom: 20px;
    padding-top: 20px;

    @include media-breakpoint-up(large) {
      display: flex;
      margin-bottom: 0;
      margin-right: -20px;
      padding-top: 0;
    }
  }

  &-navItem {
    margin: 0 20px;
    position: relative;

    & + & {
      border-top: 1px solid $border-color;
    }

    &:last-child {
      padding-right: 0;
    }

    @include media-breakpoint-up(large) {
      margin: 0;
      padding: 0 20px;
      line-height: 1;

      & + & {
        border-top: 0;
        border-left: 1px solid $border-color;
      }
    }

    &--signout {
      @include media-breakpoint-up(large) {
        display: none;
      }
    }

    &--search {
      @include media-breakpoint-up(large) {
        position: static;
      }
    }
  }

  &-navLink {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 16px 0;

    &,
    &:hover,
    &:active {
      color: $color-base;
    }

    @include media-breakpoint-up(large) {
      font-size: 15px;
      padding: 5px 0;
    }

    &--hasChildren {
      justify-content: space-between;

      &::after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        background: svg-load('../assets/icons/arrow-right.svg', fill=$color-brand) no-repeat;
        background-size: 100% auto;
        margin-left: 6px;
      }

      @include media-breakpoint-up(large) {
        &::after {
          background-image: svg-load('../assets/icons/arrow-down.svg', fill=$color-brand);
        }
      }
    }
  }

  &-navIcon {
    color: $color-brand;
    margin-left: 6px;
    font-size: 18px;
  }

  &-navFooter {
    background: $color-limestone-20;
    padding: 10px 20px 20px;
    margin-top: auto;

    @include media-breakpoint-up(large) {
      display: none;
    }
  }

  &-search {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    margin-top: 1px;
    display: block;
    visibility: visible;
    opacity: 1;
    z-index: 99;
    transition: visibility 0.15s ease, opacity 0.15s ease;

    &[hidden] {
      visibility: hidden;
      opacity: 0;
    }
  }
}


.HeaderMenu {
  $comp: &;

  background: $color-limestone-40;
  line-height: 1.3;
  visibility: hidden;
  opacity: 0;
  
  @include media-breakpoint-down(large) {
    position: fixed;
    left: 0;
    top: 60px;
    z-index: 100;
    width: 100%;
    height: calc(100vh - 60px);
    overflow-y: auto;
    padding: 40px 20px 20px;
    display: flex;
    flex-direction: column;
    transition: visibility 0.3s ease, opacity 0.3s ease;

    &.is-expanded {
      visibility: visible;
      opacity: 1;
    }
  }

  @include media-breakpoint-up(large) {
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 100;
    width: 180px;
    transform: translateX(-50%);
    padding: 8px 25px 15px;
    transition: visibility 0.15s ease, opacity 0.15s ease;

    .Header-navItem:hover & {
      visibility: visible;
      opacity: 1;
    }
  }

  &-back {
    position: absolute;
    left: 20px;
    top: 40px;
    font-size: 18px;
    color: $color-brand;
    display: none;

    @include media-breakpoint-down(large) {
      #{$comp}.has-back & {
        display: block;
      }
    }
  }

  &-inner {
    @extend %list-unstyled;
  }

  &-item {
    font-size: 16px;
    border-top: 1px solid $border-color;

    @include media-breakpoint-up(large) {
      font-size: 12px;
      border-top: 0;
      border-bottom: 1px solid $border-color;
    }
  }

  &-heading,
  &-link {
    display: block;
    padding: 16px 0;

    @include media-breakpoint-up(large) {
      padding: 8px 0;
    }
  }

  &-heading {
    font-size: inherit;
    margin: 0;
    font-weight: normal;
    padding-top: 0;
    color: $color-slate-40;

    @include media-breakpoint-up(large) {
      display: none;
    }
  }

  &-link {
    color: $color-base;
    font-weight: bold;
  }

  &-footer {
    color: $color-slate-60;

    p {
      margin-bottom: 0.5em;
    }

    @include media-breakpoint-down(large) {
      margin-top: auto;
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      color: $color-slate;
      padding: 20px 0;

      a {
        font-size: 16px;
        color: $color-brand;
        text-decoration: underline;
      }

      #{$comp}.has-back & {
        margin-left: -50px;
      }
    }

    @include media-breakpoint-up(large) {
      font-size: 12px;
      padding: 8px 0;

      a {
        color: $color-base;
        font-weight: bold;
      }
    }
  }

  //Modifiers

  &.has-back {
    @include media-breakpoint-down(large) {
      padding-left: 70px;
      transform: translateX(100%);
      transition: visibility 0.3s ease, opacity 0.3s ease, transform 0.3s ease;

      &.is-expanded {
        transform: translateX(0);
      }
    }
  }
}