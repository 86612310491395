.WorkbenchCard {
  $comp: &;

  background: #fff;
  color: $color-slate-80;
  font-size: $font-size-small;
  flex: 1;
  display: flex;
  flex-direction: column;

  &-category {
    background: var(--bg-color);
    padding: 0 20px;
    height: 70px;
    position: relative;
    display: flex;
    align-items: center;

    // Themes

    &--springBlossom {
      --bg-color: #{$color-spring-blossom};
      --text-color: #{$color-base};
      --text-bg: #{$color-spring-blossom};
    }

    &--moss {
      --bg-color: #{$color-moss};
      --text-color: #{$color-moss};
      --text-bg: #{$color-moss-20};
    }
  }

  &-categoryLabel {
    color: var(--text-color);
    background: var(--text-bg);
    font-size: 13px;
    font-weight: bold;
    padding: 3px 20px;
    display: inline-block;
    border-radius: 13px;
    position: relative;
    z-index: 1;
  }

  &-categoryImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.55;
  }

  &-title {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 20px;
    margin: 0;
    border-bottom: 1px solid $color-limestone-60;

    &--lg {
      font-size: 18px;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-right: 8px;
  }

  &-body {
    padding: 15px 20px 20px;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &-footer {
    text-align: right;
    padding: 0 20px 20px;
    margin-top: auto;
  }

  // Modifiers

  &--alt {
    .Grid-item--md6 &,
    .Grid-item--md8 & {
      @include media-breakpoint-up(large) {
        display: grid;
        grid-template-columns: 3fr auto;
        grid-template-rows: max-content max-content auto;
  
        #{$comp}-category,
        #{$comp}-title {
          grid-column-end: span 2;
        }
  
        #{$comp}-footer {
          align-self: end;
        }
      }
    }
  }
}