.FloatingFeedback {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 50;
  background: $color-brand;
  padding: 20px;
  color: #fff;
  width: 300px;
  transition: visibility 0.3s ease, opacity 0.3s ease;

  @include media-breakpoint-down(large) {
    display: none;
  }

  @include media-breakpoint-up(large) {
    &[hidden] {
      display: block;
      visibility: hidden;
      opacity: 0;
    }
  }

  &-close {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &-heading {
    color: #fff;
    padding-bottom: 15px;
    border-bottom: 1px solid $color-vivid-jade-60;
  }
}