.WorkbenchCards {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-flow: dense;
  gap: $grid-gap;
  margin-bottom: 30px;

  &-card {
    display: flex;
  }
}

