$font-family: 'GoodHome', sans-serif;

$color-slate: #353e44;
$color-slate-3: tint($color-slate, 93%);
$color-slate-5: tint($color-slate, 95%);
$color-slate-10: tint($color-slate, 90%);
$color-slate-15: tint($color-slate, 85%);
$color-slate-20: tint($color-slate, 80%);
$color-slate-30: tint($color-slate, 70%);
$color-slate-40: tint($color-slate, 60%);
$color-slate-50: tint($color-slate, 50%);
$color-slate-60: tint($color-slate, 40%);
$color-slate-70: tint($color-slate, 30%);
$color-slate-80: tint($color-slate, 20%);
$color-slate-90: tint($color-slate, 10%);



$color-vivid-jade: #4dc3b3;
$color-vivid-jade-20: tint($color-vivid-jade, 80%);
$color-vivid-jade-40: tint($color-vivid-jade, 60%);
$color-vivid-jade-60: tint($color-vivid-jade, 40%);
$color-vivid-jade-80: tint($color-vivid-jade, 20%);

$color-limestone: #d6d2c4;
$color-limestone-20: tint($color-limestone, 80%);
$color-limestone-40: tint($color-limestone, 60%);
$color-limestone-60: tint($color-limestone, 40%);
$color-limestone-80: tint($color-limestone, 20%);

$color-arctic-glacier: #9bcbeb;
$color-arctic-glacier-20: tint($color-arctic-glacier, 80%);
$color-arctic-glacier-40: tint($color-arctic-glacier, 60%);
$color-arctic-glacier-60: tint($color-arctic-glacier, 40%);
$color-arctic-glacier-80: tint($color-arctic-glacier, 20%);

$color-morning-grass: #eee52a;
$color-morning-grass-20: tint($color-morning-grass, 80%);
$color-morning-grass-40: tint($color-morning-grass, 60%);
$color-morning-grass-60: tint($color-morning-grass, 40%);
$color-morning-grass-80: tint($color-morning-grass, 20%);

$color-moss: #686f12;
$color-moss-20: tint($color-moss, 80%);
$color-moss-40: tint($color-moss, 60%);
$color-moss-60: tint($color-moss, 40%);
$color-moss-80: tint($color-moss, 20%);

$color-deep-canyon: #e04e39;
$color-deep-canyon-20: tint($color-deep-canyon, 80%);
$color-deep-canyon-40: tint($color-deep-canyon, 60%);
$color-deep-canyon-60: tint($color-deep-canyon, 40%);
$color-deep-canyon-80: tint($color-deep-canyon, 20%);

$color-spring-blossom: #f0d4dd;
$color-spring-blossom-20: tint($color-spring-blossom, 80%);
$color-spring-blossom-40: tint($color-spring-blossom, 60%);
$color-spring-blossom-60: tint($color-spring-blossom, 40%);
$color-spring-blossom-80: tint($color-spring-blossom, 20%);

$color-base: $color-slate;
$color-brand: $color-vivid-jade;
$color-highlight: $color-brand;
$color-link: $color-brand;

$color-error: $color-deep-canyon;

$border-color: rgb($color-slate, 0.1);
$border-color-solid: $color-slate-10;
$border-color-inverse: rgba(#fff, 0.1);

$grid-container-width: 1200px;
$grid-gap: 25px;
$grid-breakpoints: (
  xsmall: 0,
  small: 576px,
  medium: 768px,
  large: 1024px,
  xlarge: 1180px,
  xxlarge: 1440px
);

$grid-classes: (
  xsmall: 'xs',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  xlarge: 'xl',
  xxlarge: 'xxl'
);

$letter-spacing: 0.15em;

$font-size-base: 16px;
$font-size-large: 18px;
$font-size-small: 13px;

$page-margin-small: 20px;
$page-margin-large: 40px;

$form-control-border-color: $color-slate-30;

$btn-height: 40px;

$page-padding-positive: betterClamp($page-margin-small, $page-margin-large);
$page-padding-negative: calc(-1 * #{$page-padding-positive});
$viewport-width: #{'( ( ( 100vw ) - #{ $grid-container-width - ( $grid-gap * 2 ) } ) / 2 )'};
$page-margin-positive: #{'max( #{$viewport-width}, #{$page-padding-negative} )'};
$page-margin-negative: #{'min( -1 * #{$viewport-width}, #{$page-padding-negative} )'};
